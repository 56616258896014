import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { Ficha } from '../models/ficha.interface';
import { FichasService } from '../services/fichas.service';
import {DatePipe, Location} from '@angular/common';
import { ImagesUploadService } from '../services/images-upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagenesxFichasService } from '../services/imagenesXFicha.service';


import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-show-ficha',
  templateUrl: './show-ficha.component.html',
  styleUrls: ['./show-ficha.component.css']
})
export class ShowFichaComponent implements OnInit {
  
  private routeSub: Subscription;
  public fichaSel: Ficha;
  public caracInteriores:any[] = [];
  public caracExteriores:any[] = [];
  public active=0;

  lat: any = 19.420605089777684;
  lng: any = -99.1345640261983;

  locationChosen = false;
  imagenGet: any;
  imagesFichas: any[]=[];
  subscription: Subscription;
  public nameUser;
  estatus=false;

  constructor(private route: ActivatedRoute,
    config: NgbCarouselConfig,
    private fichasService: FichasService,
    public _location: Location,
    private imagesUploadService: ImagesUploadService,
    private sanitizer: DomSanitizer,
    private imagenesxFichasService: ImagenesxFichasService,
    private routePage: Router,
    private authService:AuthService,
    
    ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    }


  async ngOnInit() {
    const source = interval(100);
    this.subscription = source.subscribe(val => this.nameUser=this.authService.verify());
    this.routeSub = await this.route.params.subscribe(params => {
        this.fichasService.getFicha(params['id']).subscribe(
          async (res:any) => {
            this.fichaSel = res as Ficha;
            console.log(res);
            this.lng=res.logitud;
            this.lat=res.latitud;
            this.caracExteriores=res.caracExteriores.split(",");
            this.caracInteriores=res.caracInteriores.split(",");            
            //this.active=res.estatus
            if(res.estatus==1)this.estatus=true;
            if(res.estatus==0)this.estatus=false;
            
            await this.imagenesxFichasService.getImagenesXFicha(res.id).subscribe(
              async (res:any)=>{
                console.log(res);
                for (const key in res) {
                  if (Object.prototype.hasOwnProperty.call(res, key)) {
                    const element = res[key];
                    //////images///////
                      await this.imagesUploadService.getImages(element.path_img)
                      .subscribe((baseImage : any) => {
                    
                        let objectURL = URL.createObjectURL(baseImage);       
                        const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        this.imagesFichas.push(image);
                        
                      });
                    //////////////////
                  }
                }

                
              })

          },
          err => console.error(err)
        );
      
    });
    
  }
  ngAfterViewInit() {
    
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.subscription.unsubscribe();

  }
  async changeEstatus(){  

    await Swal.fire({
      title: this.estatus==true?"¿Realmente desea desactivar la publicación?":"¿Realmente desea activar la publicación?",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(this.estatus==true){
          let body={estatus:0}
          this.fichasService.update(body, this.fichaSel.id).subscribe(resp=>{
            console.log(resp);
            this.estatus=false;
          });
          console.log(body);
        }else{
          let body={estatus:1};
          this.fichasService.update(body, this.fichaSel.id).subscribe(resp=>{
            console.log(resp);
            this.estatus=true;
          });
        }
      }
    });  
  }
  
  sendWhatsApp(telefono:String){
    window.open("https://api.whatsapp.com/send?phone=+52"+telefono, "_blank");
  }
  async generarPDF() {
    let asesor:boolean;
    await Swal.fire({
      title: '¿Incluir datos de asesor?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        asesor=true;
      }
    });
    
    const pdf = new jsPDF();
    pdf.setFontSize(14);
    const logo = new Image();
    logo.src = './assets/img/icons/prueba.png';
    pdf.addImage(logo, 'jpeg', 10, 5, 15, 15);  

    pdf.text(" Inmobiliaria",35, 18);
    pdf.setFontSize(11);

    var datePipe = new DatePipe("en-US");
    const formatData = datePipe.transform(Date.now(), 'dd/MM/yyyy');
    pdf.text('Fecha: ' + formatData ,160, 18);
    pdf.line(10, 25, 200, 25);

    const imgPrincipal = this.imagesFichas[0].changingThisBreaksApplicationSecurity;
    pdf.addImage(imgPrincipal, 'jpeg', 15, 40, 175, 100); 

    pdf.setFontSize(15);
    pdf.setTextColor(0,0,128);
    pdf.text(this.fichaSel.titulo,20, 150);

    pdf.setFontSize(11);
    pdf.setTextColor(0,0,0);
    pdf.text(this.fichaSel.direccion+" "+this.fichaSel.colonia+", "+this.fichaSel.municipio+", "+this.fichaSel.estado,20, 160);

    pdf.setDrawColor(128,128,128);
    pdf.line(15, 165, 190, 165);

    let oferta="";
    (this.fichaSel.tipoOferta==="Renta")? oferta="Renta: $" : oferta="Costo: $";
    oferta=oferta+this.fichaSel.precio;
    pdf.setFontSize(13);
    pdf.text(oferta,20, 175);

    pdf.line(15, 180, 190, 180);

    pdf.setFontSize(11);
    pdf.text("Tipo de propiedad: "+this.fichaSel.tipoPropiedad,20, 185);
    pdf.text("Tipo de oferta: "+this.fichaSel.tipoOferta,115, 185);

    pdf.line(15, 189, 190, 189);
    pdf.setFontSize(13);
    pdf.text("Información general "+this.fichaSel.tipoOferta,80, 195);
    pdf.setFontSize(11);

    let infGeCont=200;
    
    
    pdf.text("Terreno: "+this.fichaSel.terreno+"m²",20, infGeCont);
    infGeCont+=5;
    if(this.fichaSel.tipoPropiedad=="Departamento"){
    
      pdf.text("Recamaras: "+this.fichaSel.recamaras,20, infGeCont);
      infGeCont+=5;
      pdf.text("Baños: "+this.fichaSel.banhos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Estacionaminetos: "+this.fichaSel.estacionamientos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Nivel: "+this.fichaSel.nivel,20, infGeCont);
      infGeCont+=5;
    }else if(this.fichaSel.tipoPropiedad=="Casa"){
      
      pdf.text("Recamaras: "+this.fichaSel.recamaras,20, infGeCont);
      infGeCont+=5;
      pdf.text("Baños: "+this.fichaSel.banhos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Estacionaminetos: "+this.fichaSel.estacionamientos,20, infGeCont);
      infGeCont+=5;
    }else if(this.fichaSel.tipoPropiedad=="Jardin de fistas"){
      
      pdf.text("Baños: "+this.fichaSel.banhos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Estacionaminetos: "+this.fichaSel.estacionamientos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Capacidad de personas: "+this.fichaSel.capacidadPersonas,20, infGeCont);
      infGeCont+=5;
    }else{
      
      pdf.text("Baños: "+this.fichaSel.banhos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Estacionaminetos: "+this.fichaSel.estacionamientos,20, infGeCont);
      infGeCont+=5;
      pdf.text("Nivel: "+this.fichaSel.nivel,20, infGeCont);
      infGeCont+=5;
    }

    if(this.fichaSel.tipoPropiedad!="Terreno"){
      pdf.line(15, 225, 190, 225);
      pdf.setFontSize(13);
      pdf.text("Caracteristicas Exteriores ",20, 230);
      pdf.text("Caracteristicas Interiores ",115, 230);
      pdf.setFontSize(10);
      let dinamicSize1=235;
      let dinamicSize2=235;
      for (const key in this.caracExteriores) {
        if (Object.prototype.hasOwnProperty.call(this.caracExteriores, key)) {
          const element = this.caracExteriores[key];
          dinamicSize1=dinamicSize1+4;
          if(element!="") pdf.text("•"+element,20, dinamicSize1);
          
        }
      }

      dinamicSize2=235;
      for (const key in this.caracInteriores) {
        if (Object.prototype.hasOwnProperty.call(this.caracInteriores, key)) {
          const element = this.caracInteriores[key];
          dinamicSize2=dinamicSize2+4;
          if(element!="") pdf.text("•"+element,115, dinamicSize2);
          
        }
      }
    }
    

    //nexet page
    pdf.addPage();
    pdf.setFontSize(13);
    pdf.text("Descripcion: ",15, 20);
    pdf.setFontSize(11);
    console.log(this.fichaSel.descripcion.length);
    let iter=0, count=25;

    while(this.fichaSel.descripcion.length>iter){
      const enters=this.fichaSel.descripcion.substring(iter,iter+95).split(/\n/g).length - 1;
      if(enters>0){
        const substrings=this.fichaSel.descripcion.substring(iter,iter+95).split(/\n/g);
        substrings.forEach(element => {
          pdf.text(element,15, count);
          count+=5
        });
      }else{
        pdf.text(this.fichaSel.descripcion.substring(iter,iter+95),15, count);
        count+=5;
      }
      iter=iter+95;
    }

    if(asesor===true){
      pdf.setFontSize(13);
      count+=5;
      pdf.text("Asesor ",15, count);
      pdf.setFontSize(12);
      count+=5;
      pdf.line(15, count, 190, count);

      count+=10;
      pdf.text('Adriana Gil',90, count);
      count+=8;
      pdf.text('(722)1234567',25, count);
      pdf.text('Contacto@inmobiliaria.com',115, count);
    }
    


    pdf.setFontSize(13);
    count+=10;
    pdf.text("Link de la propiedad ",15, count);
    pdf.setFontSize(11);
    count+=5;
    pdf.line(15, count, 190, count);
    count+=10;

    pdf.setTextColor(0,0,128);
    pdf.text('https://rincon-inmobiliario.info/#/item/'+this.fichaSel.id,15, count);
    pdf.setTextColor(0,0,0);
    count+=5;


    pdf.setFontSize(13);
    count+=10;
    pdf.text("Legalidades y Credito ",15, count);
    pdf.setFontSize(11);
    count+=5;
    pdf.line(15, count, 190, count);
    count+=10;
    pdf.text("Aplica credido: "+this.fichaSel.credito,15, count);
    count+=10;

    iter=0;
    while(this.fichaSel.situacionLegal.length>iter){
      const enters=this.fichaSel.situacionLegal.substring(iter,iter+95).split(/\n/g).length - 1;
      if(enters>0){
        const substrings=this.fichaSel.situacionLegal.substring(iter,iter+95).split(/\n/g);
        substrings.forEach(element => {
          pdf.text(element,15, count);
          count+=5
        });
      }else{
        pdf.text(this.fichaSel.situacionLegal.substring(iter,iter+95),15, count);
        count+=5;
      }
      iter=iter+95;
    }

    


    //https://inmobiliaria.tikal.mx/#/item/
    //nexet page
    
    if(this.imagesFichas.length>1){
      pdf.addPage();
      pdf.setFontSize(13);
      pdf.text("Catalogo de imagenes",15, 20);
      pdf.setFontSize(11);
      let countimg=30;
      for (let index = 1; index < this.imagesFichas.length; index++) {
        if(index!=6){
          const elementLefth = this.imagesFichas[index];
          const left = elementLefth.changingThisBreaksApplicationSecurity;
          pdf.addImage(left, 'jpeg', 15, countimg, 70, 70); 

          if(this.imagesFichas[index+1]!=null){
            const elementRight = this.imagesFichas[index+1];
            const rigth = elementRight.changingThisBreaksApplicationSecurity;
            pdf.addImage(rigth, 'jpeg', 120, countimg, 70, 70); 
            index+=1;
          }
          countimg+=80;

        }
      
    }
    }
    

    pdf.save(this.fichaSel.titulo+`.pdf`);
  }
  deleteFicha(){
    Swal.fire({
      title: '¿Realmente desea eliminar la publicación?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.fichasService.delete(this.fichaSel.id).subscribe(res=>{
          this.routePage.navigate(['/home']);
          this.imagenesxFichasService.getImagenesXFicha(this.fichaSel.id.toString()).subscribe((res:any)=>{

            for (const key in res) {
              if (Object.prototype.hasOwnProperty.call(res, key)) {
                const element = res[key];
                this.imagesUploadService.deleteImages(element.path_img).subscribe(res=>{
                  console.log(res);
                  
                });
              }
            }
            Swal.fire('Se elimino correctamente!', '', 'success');
            
          })
        })
        
        

      } else if (result.isDenied) {
        Swal.fire('Cambios no guardados!', '', 'info');
      }
    })
  }

  editaFicha(){
    Swal.fire({
      title: '¿Realmente desea editar la publicación?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.routePage.navigate(['/edicion-ficha/'+this.fichaSel.id.toString()]);
        
        

      } 
    })
  }
  
}
