import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ImagesUploadService {
  private baseUrl =  apiUrl.production+'imagenes';

  headers = new HttpHeaders();
  
  
  constructor(private http: HttpClient) { 
    this.headers.append('Content-Type', 'application/json');
  }

  upload(file: File, idFicha:Number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Img', file);
    formData.append('idFicha', idFicha.toString() );
    
    return this.http.post(`${this.baseUrl}/upload`, formData,({
      reportProgress: true,
      headers: this.headers,
    }));
  }

  getImages(path:string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('path', path);
    return this.http.get(`${this.baseUrl}/imagen/`+path, { responseType: 'blob' });
  }
  deleteImages(path_img: string){
    return this.http.delete(`${this.baseUrl}/delete/${path_img}`);
  }
  deleteImagesById(id: number){
    return this.http.delete(`${this.baseUrl}/deleteId/${id}`);
  }
  /*postFile2(fileToUpload: File) {
    const formData: FormData = new FormData();
        formData.append('Image', fileToUpload, fileToUpload.name);
        //formData.append('ComponentId', componentId);
        return this.http.post('/assets/dashboard/UploadImage', formData);
  }

  postFile(fileToUpload: File): Observable<boolean> {
    const endpoint = '/assets/dashboard/UploadImage';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(endpoint, formData, { headers: yourHeadersConfig })
      .map(() => { return true; })
      .catch((e) => this.handleError(e));
}*/

}
