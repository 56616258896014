import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ficha } from '../models/ficha.interface';
import { apiUrl } from './utils.service';

@Injectable({
providedIn: 'root'
})
export class FichasService {

private baseUrl = apiUrl.production+'fichas';

headers = new HttpHeaders();


constructor(private http: HttpClient) { 
    this.headers.append('Content-Type', 'application/json');
}
getFichas() {
    return this.http.get(`${this.baseUrl}/list`);
}
getFichasActives() {
    return this.http.get(`${this.baseUrl}/listActive`);
}
getFichasFilters(
    banhos,preciolow,
    preciohigth,tipoOferta,
    recamaras,tamanho,
    localizacion) {
    return this.http.get(
        `${this.baseUrl}/filters/${banhos}/${preciolow}/${preciohigth}/${tipoOferta}/${recamaras}/${tamanho}/${localizacion}`);
}

getFicha(id: string) {
    return this.http.get(`${this.baseUrl}/${id}`);
}

delete(id: number) {
    return this.http.delete(`${this.baseUrl}/delete/${id}`);
}
create(ficha: Ficha) {
    return this.http.post(`${this.baseUrl}/create`, ficha);
}
update(body,id){
    let req={...body,...id}
    return this.http.put(`${this.baseUrl}/update/${id}`, body);
}




}
