import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import Swal from 'sweetalert2';
import { User } from '../models/user.interface';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  formLogin: FormGroup;

  constructor(private usersService: UsersService,
              public _formBuilder: FormBuilder,
              private route: Router) { 
    this.formLogin = this._formBuilder.group({
      email: ["", [Validators.required]],
      contrasenha: ["", [Validators.required]],

    });

  }

  ngOnInit() {
  }
  login(){
    let user :User;
    user = this.formLogin.value;
    
    this.usersService.verify(user).subscribe(
      (resp:User)=>{
        Swal.fire({
          icon: 'success',
          title: 'Bienbenido '+resp.nombre,
          showConfirmButton: false,
          timer: 1500
        });
        localStorage.setItem('nameUserLoged', resp.nombre);
        localStorage.setItem('emailUserLoged', resp.email);
        this.formLogin.reset();

        this.route.navigate(['/home']);

        
    },
    (error:any) =>{
      console.log('oops', error);
      Swal.fire({
        icon: 'error',
        title: 'Usuario no encontrado, verifique los datos',
        showConfirmButton: true,
        
      })
    } 
    )
  }

}
