export const TYPE_OFFSALE=[
{name:'Venta',value:'Venta'},
{name:'Renta',value:'Renta'}
];
export const TYPE_PROPERTY=[
    {name:'Departamento',value:'Departamento'},
    {name:'Casa',value:'Casa'},
    {name:'Terreno',value:'Terreno'},
    {name:'Bodega',value:'Bodega'},
    {name:'Local Comercial',value:'Comercial'},
    {name:'Oficinas',value:'Oficinas'},
    {name:'Jardin de fistas',value:'Jardin de fistas'}
];
export const DESICION=[
    {name:'Si',value:'Si'},
    {name:'No',value:'No'},
];
export const CARATERISTICS_INTER_DEP=[
    "Calefaccion",
    "Piso Loseta", 
    "Extractor en Baños",
    "Acceso a TV de Paga ",
    "Aire Acondicionado",
    "Armarios Empotrados",
    "Gimnacio",
    "Intercomunicador",
    "Internet de banda ancha disponible",
    "Cosina Equipada",
    "Piso Alfombra",
    "Sistema de alarma",
    "Cuarto de servicio",
    "Piso Duela",

];
export const CARATERISTICS_EXT_DEP=[
    "Estacionamiento por vistas",
    "Salon de usos múltiples", 
    "Área de juegos inflables",
    "Totalmente cercado",
    "Elevador",
    "Roof Garden",
    "Jacuzzi",
    "Balcón",
    "Estacionamiento Abierto",
    "Estacionamiento Vigilado",
    "Garaje",
    "Garaje a distancia",
    "Alberca",
    "Cancha con tenis",
    "Terraza",
    
];
export const CARATERISTICS_INTER_HAUSE=[
    "Piso Loseta", 
    "Acceso a TV de Paga ",
    "Aire Acondicionado",
    "Armarios Empotrados",
    "Calefaccion",
    "Chimenea",
    "Gimnacio",
    "Intercomunicador",
    "Internet de banda ancha disponible",
    "Jacuzzi",
    "Piso Duela",
    "Cosina Equipada",
    "Sistema de alarma",
    "Estudio",
    "Cuarto de servicio",

];
export const CARATERISTICS_EXT_HAUSE=[
    "Balcón",
    "Cancha con tenis",
    "Estacionamiento Vigilado",
    "Garaje",
    "Estacionamiento Techado",
    "Patio",
    "Terraza",
    "Totalmente cercado",
    "Área de entrenamiento al aire libre",
    "Jardín",
    "Estacionamiento Abierto",
    "Alberca",
    "Estacionamiento por vistas",
    "Roof Garden",
    "Área de juegos infantiles",
    
];
export const SUBTIPE_DEPARTAMENT=[
    "Departamento",
    "Dúplex",
    "Condominio Horizontal",
    "Loft",
    "Penthause",
    "Estudio",
    "Compartido",
    "Otro"
]
export const SUBTIPE_HAUSE=[
    "Casa",
    "Villa",
    "Casa en Fracionamiento",
    "Casa en Condominio",
    "Casa Vacacional",
    "Quinta/Hacienda",
    "Otro"
]
export const TYPE_CREDITS=[
    "No aplica",
    "Fovissste",
    "Infonavit",
    "Bancario",
]
export const CARATERISTICS_INT_COMERCIAL=[
    "Aire Acondicionado",
    "Internet de banda ancha disponible",
    "Sistema de alarma"
]
export const CARATERISTICS_EXT_COMERCIAL=[
    "Carpot",
    "Estacionamiento Abierto",
    "Estacionamiento Vigilado",
    "Garaje",
    "Garaje a distancia",

]