import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ImagenXFicha } from '../models/imagenesXFicha.interface';
import { User } from '../models/user.interface';

@Injectable({
providedIn: 'root'
})
export class UtilsService {

seachValue:string= "";


headers = new HttpHeaders();


constructor() { 
}
search(){
    return this.seachValue;
}
changeSearch(valueSearch){
    this.seachValue = valueSearch;
    
}



}
export const apiUrl={
    local:"http://localhost:3000/",
    development: "//inmobiliaria.tikal.mx/",
    production: "//api.rincon-inmobiliario.info/"
}