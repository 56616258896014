import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    public searchText:string="";
    constructor(private utilsService: UtilsService) { }

    ngOnInit() {}
    toTop() {
        console.log("voy");
        
        document.getElementById("Sections").scrollIntoView();
    }
    search(){
        this.utilsService.changeSearch(this.searchText);
        document.getElementById("Sections").scrollIntoView();

    }
    resetSearch(){
        this.utilsService.changeSearch("");
        this.searchText="";

    }
}
