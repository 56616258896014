import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ImagenXFicha } from '../models/imagenesXFicha.interface';
import { apiUrl } from './utils.service';

@Injectable({
providedIn: 'root'
})
export class ImagenesxFichasService {
private baseUrl = apiUrl.production+'imagenesXFicha';

headers = new HttpHeaders();


constructor(private http: HttpClient) { 
    this.headers.append('Content-Type', 'application/json');
}
getImagenesXFicha(id: string) {
    return this.http.get(`${this.baseUrl}/imagesxficha/`+id);
}
getOneImageneXFicha(id: string) {
    return this.http.get(`${this.baseUrl}/imagenxficha/`+id);
}


delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
}
create(imagenXFicha: ImagenXFicha) {
    return this.http.post(`${this.baseUrl}/create`, imagenXFicha);
}




}
