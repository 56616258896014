import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NewFichaGuard implements CanActivate {
  subscription: Subscription;

  constructor(private authService:AuthService,
    ){

  }
  canActivate(): boolean {
    let verify=this.authService.verify()
    console.log(verify);
    
    if(verify==="" || verify===undefined || verify===null){
      Swal.fire({
        icon: 'error',
        title: 'Error de permisos UnU',
        text: 'Contacte al administrador',
        showConfirmButton: true,
      })
      return false;
    }
    return true;
}
}
