import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { CreatePropertyComponent } from './create-property/create-property.component';
import { ShowFichaComponent } from './show-ficha/show-ficha.component';
import { NewFichaGuard } from './guards/new-ficha.guard';
import { EditFichaComponent } from './edit-ficha/edit-ficha.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    //{ path: 'user-profile',     component: ProfileComponent },
    //{ path: 'register',           component: SignupComponent },
   // { path: 'landing',          component: LandingComponent },
    { path: 'login',          component: LoginComponent },
    { path: 'new-property',          
      component: CreatePropertyComponent,
      canActivate: [NewFichaGuard]},
      { path: 'edicion-ficha/:id',       
      component: EditFichaComponent,
      canActivate: [NewFichaGuard]},
    {path: 'item/:id', component: ShowFichaComponent},
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
