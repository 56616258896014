import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ImagenXFicha } from '../models/imagenesXFicha.interface';
import { User } from '../models/user.interface';

@Injectable({
providedIn: 'root'
})
export class AuthService {



headers = new HttpHeaders();


constructor() { 
}
verify(){
    return localStorage.getItem('nameUserLoged');
}




}
