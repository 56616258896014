import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { CARATERISTICS_EXT_COMERCIAL, CARATERISTICS_EXT_DEP, CARATERISTICS_EXT_HAUSE, CARATERISTICS_INTER_DEP, CARATERISTICS_INTER_HAUSE, CARATERISTICS_INT_COMERCIAL, DESICION, SUBTIPE_DEPARTAMENT, SUBTIPE_HAUSE, TYPE_CREDITS, TYPE_OFFSALE, TYPE_PROPERTY } from '../data-utils/data-utils';
import { Ficha } from '../models/ficha.interface';
import { ImagenXFicha } from '../models/imagenesXFicha.interface';
import { FichasService } from '../services/fichas.service';
import { ImagenesxFichasService } from '../services/imagenesXFicha.service';
import { ImagesUploadService } from '../services/images-upload.service';

@Component({
  selector: 'app-create-property',
  templateUrl: './create-property.component.html',
  styleUrls: ['./create-property.component.css']
})
export class CreatePropertyComponent implements OnInit {
  focus;
  focus1;
  public tipe_offsale = TYPE_OFFSALE;
  public tipe_property = TYPE_PROPERTY;
  public desiciones = DESICION;
  public type_credits=TYPE_CREDITS;
  public tipePropertySelect:String="";
  fruits: Array<string> = ["apple", "pear", "kiwi", "banana", "grape", "strawberry", "grapefruit", "melon", "mango", "plum"];
  caracteristicsInt: Array<string> =[];
  caracteristicsExt: Array<string> = [];
  subtipe: Array<string> = [];

  
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  
  //imagenes
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;

  //google maps
  lat: number = 19.420605089777684;
  lng: number = -99.1345640261983;
  locationChosen = false;



  constructor(public _formBuilder: FormBuilder,
              public imagesUploadService: ImagesUploadService,
              private fichasService: FichasService,
              private imagenesxFichasService: ImagenesxFichasService,
              private route: Router,

              ) { 

    this.firstFormGroup = this._formBuilder.group({
      tipoOferta: ["", [Validators.required]],
      tipoPropiedad: ["", [Validators.required]],

      subTipo: ["", ], //reactive

      titulo: ["", [Validators.required]],
      descripcion: ["", [Validators.required]],
      
      //info departamento - casa
      recamaras: [, [Validators.required]],
      banhos: [, [Validators.required, Validators.pattern("^[0-9]$")]],
      mContruidos: [ , [Validators.required]],
      estacionamientos: [, [Validators.required]],
      amueblado: ["", [Validators.required]],
      capacidadPersonas: ["", [Validators.required]],


      //solo para depa
        nivel: [],
      //solo para casa
        terreno: [""],

      precio: [ , [Validators.required]],
      situacionLegal: [ , [Validators.required]],
      credito: [ , [Validators.required]],

      
      fecha_publicacion: [Date.now(), [Validators.required]],

      
      
      //ubicacion
      estado: ["", [Validators.required]],
      municipio: ["", [Validators.required]],
      colonia: ["", [Validators.required]],
      direccionPublica: ["", [Validators.required]],
      direccion: ["", [Validators.required]],
      latitud: [, [Validators.required]],
      logitud: [, [Validators.required]],

      //mas sobre pripiedad
      caracInteriores: [[]], //reactive
      caracExteriores: [[]], //reactive

      //contacto
      contacto: ["", [Validators.required]],
      telContacto: ["", [Validators.required, Validators.pattern('[0-9]{10}')]],
      email: ["", [Validators.required, Validators.email]],

      estatus: [1]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: [[], [Validators.minLength(2)]],
    });

  }

  
  ngOnInit(): void {
    //this.imageInfos = this.imagesUploadService.getFiles();
    
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File, idFicha:Number): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.imagesUploadService.upload(file, idFicha).subscribe(
        (event: any) => {
          console.log(event.fileName);

          let imagenesXFicha:ImagenXFicha={
            id: null,
            id_ficha: Number(idFicha),
            path_img: event.fileName
          }
          this.imagenesxFichasService.create(imagenesXFicha).subscribe(
            (resp:any) => {
              console.log(resp);
              
            }
          );

          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            //this.imageInfos = this.imagesUploadService.getFiles();
          }
        },
        (err: any) => {
          console.log(err);
          
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }
      );
    }
  }

  uploadFiles(idFicha: Number): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i], idFicha);
      }
    }
  }
  //maps

  onChoseLocation(event) {
    console.log(event);
    
    this.lat = event.coords.lat;
    this.firstFormGroup.controls['latitud'].setValue(event.coords.lat);

    this.lng = event.coords.lng;
    this.firstFormGroup.controls['logitud'].setValue(event.coords.lng);
    this.locationChosen = true;
  }
  setLocationInputs(){
    
  }
  //control forms
  changeTipeProperty(type:string){
    console.log(type);
    
    this.tipePropertySelect=type;
    if(type==="Casa"){
      this.caracteristicsInt=CARATERISTICS_INTER_HAUSE;
      this.caracteristicsExt=CARATERISTICS_EXT_HAUSE;
      this.subtipe=SUBTIPE_HAUSE;
      this.firstFormGroup.get("nivel").setValue(null);
      this.firstFormGroup.controls["terreno"].setValidators([Validators.required]);
      this.firstFormGroup.controls["nivel"].clearValidators();
      
    }else if(type==="Departamento"){
      this.caracteristicsInt=CARATERISTICS_INTER_DEP;
      this.caracteristicsExt=CARATERISTICS_EXT_DEP;
      this.subtipe=SUBTIPE_DEPARTAMENT;
      this.firstFormGroup.get("terreno").setValue(null);
      this.firstFormGroup.controls["nivel"].setValidators([Validators.required]);
      this.firstFormGroup.controls["terreno"].clearValidators();
      
    }else if(type==="Terreno"){

      this.firstFormGroup.get("capacidadPersonas").setValue(null);
      this.firstFormGroup.controls["capacidadPersonas"].setValidators(null);
      
    }else{
      this.caracteristicsInt=CARATERISTICS_INT_COMERCIAL;
      this.caracteristicsExt=CARATERISTICS_EXT_COMERCIAL;
    }
    this.firstFormGroup.controls['terreno'].updateValueAndValidity();
    this.firstFormGroup.controls['nivel'].updateValueAndValidity();
    this.firstFormGroup.controls['capacidadPersonas'].updateValueAndValidity();
  }
  //create ficha
  createFicha() {
    const ficha : Ficha=this.firstFormGroup.value;
    this.fichasService.create(ficha)
      .subscribe(
        (res:any) => {
          
          console.log(res.result.insertId);
          //console.log(res.file.filename);

          this.uploadFiles(res.result.insertId)
          Swal.fire({
            icon: 'success',
            title: 'Publicación creada correctamente',
            showConfirmButton: false,
            timer: 1500
          })
          this.firstFormGroup.reset();
          this.selectFiles=null;
          this.route.navigate(['/home']);

          //this.router.navigate(['/games']);
        },
        err => console.error(err)
      )
  }
}
