import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { async, interval, Observable } from 'rxjs';
import { MatTableDataSource  } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FichasService } from 'src/app/services/fichas.service';
import { Router } from '@angular/router';
import { ImagenesxFichasService } from 'src/app/services/imagenesXFicha.service';
import { ImagesUploadService } from 'src/app/services/images-upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { Ficha } from 'src/app/models/ficha.interface';

@Component({
  selector: 'app-baner-fichas',
  templateUrl: './baner-fichas.component.html',
  styleUrls: ['./baner-fichas.component.css']
})
export class BanerFichasComponent implements OnInit {
  public form: FormGroup;
  fichas:any =[];
  data = [];
  fichasWithImagesFichas: any[]=[];
  public tipoOferta="";
  public localizacion="";

  page = 0;
  size = 4;
  backupF:any =[];
  precio: string;
  preciolow: string;
  preciohigth: string;
  recamaras: string;
  tamanho: string;
  banhos: string;
  searchText: string;
  public userName;
  doneLoading=false;
  constructor(private formBulder: FormBuilder,
              private fichasService: FichasService,
              private router: Router,
              private imagenesxFichasService: ImagenesxFichasService,
              private imagesUploadService: ImagesUploadService,
              private sanitizer: DomSanitizer,
              private utilsService: UtilsService,
              private authService:AuthService,
    ){
    this.form = formBulder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ])
      
    });
  
  }
  async ngOnInit() {
    this.userName=this.authService.verify();
    const source = interval(10);
    source.subscribe(val => this.searchText=this.utilsService.search());
    this.getListFichasByRol();
    
  }
  async getListFichasByRol(){
    if(this.authService.verify()!=null){
      await this.fichasService.getFichas().subscribe(async (res:any )=> {
        this.buildItemObj(res);
        },
        err => console.error(err)
      );
    }else{
      await this.fichasService.getFichasActives().subscribe(async (res:any )=> {
        this.buildItemObj(res);
        },
        err => console.error(err)
      );
    }
  }
  async buildItemObj(res){
    this.fichas = res;
    
    
    this.backupF=res;
    
    let index=1; 
    for (const key in res) {
      if (Object.prototype.hasOwnProperty.call(res, key)) {
        const element = res[key];
        
        await this.imagenesxFichasService.getOneImageneXFicha(element.id).subscribe(
          async (res:any)=>{
            const path_img=res[0].path_img;
            await this.imagesUploadService.getImages(path_img).subscribe(
              (baseImage : any) => {

                let objectURL = URL.createObjectURL(baseImage);       
                const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                const obj:any={
                  ...element,
                  image: image,
                }
                this.fichasWithImagesFichas.push(obj);
                this.backupF=this.fichasWithImagesFichas;

                  
                if(this.fichas.length==index){
                  this.data=this.fichasWithImagesFichas;
                  this.doneLoading=true;
                  this.getData({pageIndex: this.page, pageSize: this.size});                  
                }
                index++;

                
              }
            )
          }
        )
      }
    }
  

  }
  getData(obj) {
   
    //if(this.doneLoading==false)this.getData(obj);
    let index=0,
        startingIndex=obj.pageIndex * obj.pageSize,
        endingIndex=startingIndex + obj.pageSize;

    this.data = this.fichasWithImagesFichas.filter(() => {
      index++;
      return (index > startingIndex && index <= endingIndex) ? true : false;
    });
    
  }
  filtrar(){
    //this.getData({pageIndex: this.page, pageSize: this.size});
  }
  verItem(id:Number){
    this.router.navigate(["/item/"+id]);
  }
  fb(e, id) {
    let url = 'https://rincon-inmobiliario.info/#/home';
    let text = 'rincon-inmobiliario.info/#/item/'+id;
    
    e.preventDefault();
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' +encodeURIComponent(url)+"?&name=[["+text+"]]&redirect_uri="+text,
      'facebook-popup',
      'height=350,width=600'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }


  cleanFilter(){
    this.getListFichasByRol();
    this.banhos="";
    this.preciolow="";
    this.preciohigth="";
    this.tipoOferta="";
    this.recamaras="";
    this.tamanho="";
    this.localizacion="";
    this.getData({pageIndex: this.page, pageSize: this.size}); 
  }
  filterPublications(){
    let b=this.banhos==undefined || this.banhos==""?"-":this.banhos;
    let pl=this.preciolow==undefined || this.preciolow==""?"-":this.preciolow;
    let ph=this.preciohigth==undefined || this.preciohigth==""?"-":this.preciohigth;
    let tp=this.tipoOferta==""?"-":this.tipoOferta;
    let r=this.recamaras==undefined || this.recamaras==""?"-": this.recamaras;
    let t=this.tamanho==undefined || this.tamanho==""?"-":this.tamanho;
    let l= this.localizacion==""?"-":this.localizacion;
    console.log(b,pl,ph,tp,r,t,l);
    
    this.fichasService.getFichasFilters(b,pl,ph,tp,r,t,l).subscribe((res:any)=>{
      console.log(res);
      if(this.authService.verify()!=null){
        
      }else{
        res=res.filter((element:Ficha)=>element.estatus==1);
      }
      console.log(res);
      this.data=[];
      this.fichasWithImagesFichas=[]
      this.buildItemObj(res);
      this.getData({pageIndex: this.page, pageSize: this.size}); 
    })
  }
}
